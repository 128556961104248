<template>
  <div>
    <PageHeader />
    <!-- /* <b-row>
      <b-col>
        <b-form-group>
          <b-form-radio-group
            v-model="selectedGameType"
            button-variant="outline-dark"
            buttons
          >
            <b-form-radio
              v-for="(game, index) in filteredGameTypes"
              :key="index"
              :value="game.type"
              >{{ game.name }}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <component :is="gameListComponent" /> */ -->
    <ProviderGameList />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  page: {
    title: 'ตั้งค่าเกม',
  },
  components: {
    // MiniGameList: () => import('../../../components/games/mini-games/list'),
    ProviderGameList: () =>
      import('../../../components/games/provider-games/list'),
    // LottoGameList: () => import('../../../components/games/lotto/list'),
  },
  data() {
    return {
      selectedGameType: '',
    }
  },
  computed: {
    ...mapGetters(['gameTypes']),
    filteredGameTypes() {
      return this.gameTypes || []
    },
    gameListComponent() {
      switch (this.selectedGameType) {
        case 'MINI_GAME':
          return 'MiniGameList'
        case 'CASINO':
          return 'ProviderGameList'
        case 'LOTTO':
          return 'LottoGameList'
        default:
          return ''
      }
    },
  },
  watch: {
    filteredGameTypes(val) {
      if (val.length > 0 && !this.selectedGameType) {
        this.selectedGameType = val[0].type
      }
    },
  },
  async created() {
    await this.fetchProviderGames()
    if (this.filteredGameTypes.length > 0) {
      this.selectedGameType = this.filteredGameTypes[0].type
    }
  },
  methods: {
    ...mapActions(['fetchProviderGames']),
  },
}
</script>
